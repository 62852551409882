import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';

import axios from 'axios';
import API from '@/connect/config';

import { jwtDecode } from 'jwt-decode';

Vue.use(Vuex);

let globalRouter = null;

export const setGlobalRouter = (router) => {
  // กำหนดค่า router ให้กับตัวแปร globalRouter
  globalRouter = router;
};

export default new Vuex.Store({
  state: {
    // your state properties
    toastMessage: {},
    distantAPI: {},
    saveStates: [],
    saveChang: '',
    savePage: null,
    save_announce_filter: [],
  },
  mutations: {
    async get_announce_filter(state, payload) {
      state.save_announce_filter = [];
      state.save_announce_filter = payload;
    },
    addPage(state, { payload }) {
      state.savePage = null;
      state.savePage = payload;
    },

    addChang(state, { payload }) {
      state.saveChang = '';
      state.saveChang = payload;
    },
    addStates(state, { payload }) {
      state.saveStates = [];
      state.saveStates.push(payload);
    },

    setToastMessage(state, { title, message, variant }) {
      state.toastMessage = {
        title: title,
        message: message,
        variant: variant,
      };
    },
  },
  actions: {
    async addPage({ commit, dispatch }, payload) {
      commit('addPage', { payload });
    },
    async addChang({ commit, dispatch }, payload) {
      commit('addChang', { payload });
    },
    async addStates({ commit, dispatch }, payload) {
      commit('addStates', { payload });
    },
    imageUrl(image) {
      if (image) {
        const url = window.URL.createObjectURL(image);
        return url;
      }
    },

    async getAuthorization({ commit, dispatch }) {
      const url = `${API}token`;
      const headers = {
        Authorization: localStorage.getItem('refresh_token'),
      };
      const res = await axios.post(url);
      if (res.data.message.access_token) {
        console.log('access');
        return res.data.message.access_token;
      } else {
        console.log('no access');
        globalRouter.push({ name: 'login' });
      }
    },
    async IN_AUTHORIZATION({ commit, dispatch }) {
      const url = `${API}token`;
      const headers = {
        Authorization: localStorage.getItem('refresh_token'),
      };
      const res = await axios.post(url, headers);
      return res.data.message.access_token;
    },
    async getAll({ commit, dispatch }, _URL) {
      const url = `${_URL}&_page=1&_limit=100`;
      const res = await axios.get(url);
      const total = Math.ceil(Number(res.data.message.total) / 100);
      const urls = Array.from({ length: total }, (_, i) => `${_URL}&_page=${i + 1}&_limit=100`);
      const responses = await Promise.all(urls.map((url) => axios.get(url)));
      const res_data_ = responses.flatMap((response) => response.data.message.result);
      return res_data_;
    },
    async get_announce_filter({ commit, dispatch }, _category) {
      const { userId } = JSON.parse(localStorage.getItem('company_jwt_vis'));
      const baseURL = `${API}announce/${userId}?_page=1&_limit=100&_sort=-1`;
      const categoryURL = `${API}announce/${userId}?category=${_category}&_page=1&_limit=100&_sort=-1`;
      const url = _category === 'no_category' ? baseURL : categoryURL;
      const res = await axios.get(url);
      const total = Math.ceil(Number(res.data.message.total) / 100);
      const urls = Array.from({ length: total }, (_, i) => (_category === 'no_category' ? `${API}announce/${userId}?_page=${i + 1}&_limit=100&_sort=-1` : `${API}announce/${userId}?category=${_category}&_page=${i + 1}&_limit=100&_sort=-1`));
      const responses = await Promise.all(urls.map((url) => axios.get(url)));
      const allResults = responses.flatMap((res) => res.data.message.result);
      const { tag } = JSON.parse(localStorage.getItem('profile_visitor'));
      const filteredData = allResults.filter((item) => item.tag.some((t) => tag.includes(t)));
      filteredData.sort((a, b) => new Date(b.registerDate) - new Date(a.registerDate));
      return filteredData;
    },
    async getData({ commit, dispatch }, _url) {
      const url = `${API}${_url}`;
      const res = await axios.get(url);
      return res.data;
    },

    async getImageURL({ dispatch }, _data) {
      const response = await fetch(`${API}view/images/${_data}`, { responseType: 'arraybuffer' });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return url;
    },
    async getImage({ dispatch }, _data) {
      const response = await fetch(`${API}view/images/${_data}`, { responseType: 'arraybuffer' });
      const blob = await response.blob();
      const type = 'image/webp';
      const file = new File([blob], `${_data}`, { type: type });
      return file;
    },
    async getImageLogo({ dispatch }, _data) {
      const response = await fetch(`${API}view/logo/${_data}`, { responseType: 'arraybuffer' });
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      return url;
    },
    async postData({ commit, dispatch }, { _url, _data }) {
      if (_url === 'login') {
        const url = `${API}${_url}`;
        const res = await axios.post(url, _data);
        return res.data;
      } else {
        const access_token = await dispatch('IN_AUTHORIZATION');
        const url = `${API}${_url}`;

        const res = await axios.post(url, _data);
        return res.data;
      }
    },

    async getJwtDecode({ commit, dispatch }, value) {
      const data = jwtDecode(value);
      return data;
    },
  },
  getters: {
    getStates: (state) => state.saveStates,
    getChang: (state) => state.saveChang,
    getPage: (state) => state.savePage,
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
